<!-- begin::Header -->
<header
    class="m-grid__item m-header {{ hasFilterBar ? 'has-filter-bar' : '' }}"
    data-minimize="minimize"
    data-minimize-offset="200"
    data-minimize-mobile-offset="200"
>
    <div class="m-header__top" *ngIf="!isIframeMode">
        <div class="m-container m-container--responsive m-container--full-height m-page__container">
            <div class="m-stack m-stack--ver m-stack--desktop">
                <!-- begin::Brand -->
                <div class="m-stack__item m-brand">
                    <div class="m-stack m-stack--ver m-stack--general m-stack--inline">
                        <div class="m-stack__item m-stack__item--middle m-brand__logo">
                            <a [routerLink]="ROUTE.ROOT" class="m-brand__logo-wrapper">
                                <img
                                    id="EMMA-Logo"
                                    class="emma-logo"
                                    src="assets/media/emma/img/logo/emma-logo.svg"
                                    alt="EMMA"
                                    i18n-alt
                                />
                            </a>
                        </div>
                        <div class="m-stack__item m-stack__item--middle m-brand__tools">
                            <emma-select
                                selectClass="ng-select-btn-emmadarkblue ng-select--pill ng-select-drop-auto-width ng-select-sm selectAppPicker"
                                [items]="userApps"
                                [(ngModel)]="selectedAppId"
                                (ngModelChange)="changeCurrentApp($event)"
                                groupBy="accountName"
                                valueKey="appConfig.appId"
                                labelKey="appConfig.name"
                                notFoundText="App no encontrada"
                                [searchable]="true"
                                [isFullWidth]="false"
                                placeholder="Cargando..."
                                i18n-placeholder
                                i18n-notFoundText
                            ></emma-select>
                            <a
                                id="m_aside_header_menu_mobile_toggle"
                                class="m-brand__icon m-brand__toggler m--visible-tablet-and-mobile-inline-block"
                            >
                                <span></span>
                            </a>
                            <a
                                id="m_aside_header_topbar_mobile_toggle"
                                class="m-brand__icon m--visible-tablet-and-mobile-inline-block"
                            >
                                <i class="flaticon-more" aria-hidden="true"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="m-stack__item m-stack__item--fluid m-header-head" id="m_header_nav">
                    <div id="m_header_topbar" class="m-topbar m-stack m-stack--ver m-stack--general">
                        <emma-date-range-group-picker
                            *ngIf="isDateSelectorVisible"
                            ($change)="changeDate($event)"
                            class="topbar-location"
                            [hasGroupBy]="true"
                        ></emma-date-range-group-picker>
                        <div class="m-stack__item m-topbar__nav-wrapper">
                            <emma-user-menu [user]="user" [currentApp]="currentApp"></emma-user-menu>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="m-header__bottom">
        <div class="m-container m-container--responsive m-container--full-height m-page__container">
            <div class="m-stack m-stack--ver m-stack--desktop">
                <div class="m-stack__item m-stack__item--middle m-stack__item--fluid">
                    <button
                        class="m-aside-header-menu-mobile-close m-aside-header-menu-mobile-close--skin-light"
                        id="m_aside_header_menu_mobile_close_btn"
                    >
                        <i class="la la-close" aria-hidden="true"></i>
                    </button>
                    <div
                        id="m_header_menu"
                        class="m-header-menu m-aside-header-menu-mobile m-aside-header-menu-mobile--offcanvas m-header-menu--skin-dark m-header-menu--submenu-skin-light m-aside-header-menu-mobile--skin-light m-aside-header-menu-mobile--submenu-skin-light"
                    >
                        <ul class="m-menu__nav m-menu__nav--submenu-arrow">
                            <ng-container *ngFor="let item of menuItems">
                                <li
                                    *ngIf="!item.hidden"
                                    [ngClass]="{
                                        'm-menu__item': true,
                                        'm-menu__item--submenu': item.menu && item.menu.length,
                                        'm-menu__item--rel': true,
                                        'm-menu__item--active': isActive(item),
                                        'm-menu__item--disabled': item.disabled,
                                    }"
                                    data-menu-submenu-toggle="hover"
                                >
                                    <span
                                        *ngIf="!item.disabled && item.menu"
                                        [routerLink]="item.route"
                                        routerLinkActive="m-menu__item--active"
                                        [routerLinkActiveOptions]="{ exact: true }"
                                        [ngClass]="{
                                            'm-menu__link': true,
                                            'm-menu__toggle': item?.menu?.length,
                                        }"
                                    >
                                        <i
                                            [class]="'m-menu__link-icon la la-' + item.icon"
                                            aria-hidden="true"
                                        ></i>
                                        <span class="m-menu__link-text">
                                            {{ item.title }}
                                        </span>
                                        <ng-container *ngIf="!item.disabled && item?.menu?.length">
                                            <i
                                                class="m-menu__hor-arrow la la-angle-down"
                                                aria-hidden="true"
                                            ></i>
                                            <i
                                                class="m-menu__ver-arrow la la-angle-right"
                                                aria-hidden="true"
                                            ></i>
                                        </ng-container>
                                    </span>
                                    <a
                                        *ngIf="!item.disabled && !item.menu"
                                        [routerLink]="item.route"
                                        routerLinkActive="m-menu__item--active"
                                        [routerLinkActiveOptions]="{ exact: true }"
                                        class="m-menu__link"
                                    >
                                        <i
                                            [class]="'m-menu__link-icon la la-' + item.icon"
                                            aria-hidden="true"
                                        ></i>
                                        <span class="m-menu__link-text">
                                            {{ item.title }}
                                        </span>
                                    </a>
                                    <span
                                        *ngIf="item.disabled"
                                        routerLinkActive="m-menu__item--active"
                                        [routerLinkActiveOptions]="{ exact: true }"
                                        [ngClass]="{
                                            'm-menu__link': true,
                                            'm-menu__toggle': item.menu && item.menu.length,
                                        }"
                                    >
                                        <i
                                            [class]="'m-menu__link-icon la la-' + item.icon"
                                            aria-hidden="true"
                                        ></i>
                                        <span class="m-menu__link-text">
                                            {{ item.title }}
                                        </span>
                                    </span>
                                    <div
                                        *ngIf="!item.disabled && item?.menu?.length"
                                        class="m-menu__submenu m-menu__submenu--classic m-menu__submenu--left"
                                    >
                                        <span class="m-menu__arrow m-menu__arrow--adjust"></span>
                                        <ul class="m-menu__subnav">
                                            <li *ngFor="let subitem of item.menu" class="m-menu__item">
                                                <span
                                                    *ngIf="subitem.disabled"
                                                    class="m-menu__link m-menu__link--disabled"
                                                >
                                                    <i
                                                        [class]="'m-menu__link-icon la la-' + subitem.icon"
                                                        aria-hidden="true"
                                                    ></i>
                                                    <span class="m-menu__link-text">
                                                        {{ subitem.title }}
                                                    </span>
                                                </span>
                                                <a
                                                    *ngIf="!subitem.disabled"
                                                    [routerLink]="subitem.route"
                                                    routerLinkActive="m-menu__item--active"
                                                    [routerLinkActiveOptions]="{ exact: true }"
                                                    class="m-menu__link"
                                                >
                                                    <i
                                                        [class]="'m-menu__link-icon la la-' + subitem.icon"
                                                        aria-hidden="true"
                                                    ></i>
                                                    <span class="m-menu__link-text">
                                                        {{ subitem.title }}
                                                    </span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="m-header__date-sticky" *ngIf="isIframeMode && isDateSelectorVisible">
        <div class="m-stack__item m-stack__item--fluid m-header-head" id="m_header_nav">
            <div id="m_header_topbar" class="m-topbar m-stack m-stack--ver m-stack--general">
                <emma-date-range-group-picker
                    ($change)="changeDate($event)"
                    class="topbar-location"
                    [hasGroupBy]="true"
                    [reducedSize]="true"
                ></emma-date-range-group-picker>
            </div>
        </div>
    </div>
</header>
