import { LOCAL_STORAGE_KEY, LocalStorageService } from '@emma-services/local-storage.service';
import { EMMAUserAppInfo, EMMA_ROLES, User } from 'emma-common-ts/emma';
import { get } from 'lodash';

export function getAuthKeys(): {
  accessToken: string;
  apiKey: string;
  accountApiKey: string;
} | null {
  try {
    const localStorageService = new LocalStorageService();
    const currentApp = localStorageService.get(LOCAL_STORAGE_KEY.CURRRENT_APP);
    const currentUser = localStorageService.get(LOCAL_STORAGE_KEY.CURRENT_USER);
    if (currentApp && currentUser) {
      const accessToken = get(currentUser, 'access_token');
      const apiKey = get(currentApp, 'appConfig.apiKey');
      const accountApiKey = get(currentApp, 'accountApiKey');
      return {
        accessToken,
        apiKey,
        accountApiKey,
      };
    }
  } catch (ex) {
    console.error(ex);
  }

  return null;
}

export const isAdminUser = (user?: User, app?: EMMAUserAppInfo) =>
  Boolean(user && app && app.roleId === EMMA_ROLES.ADMIN);

export const isPowerAdminUser = (user?: User, app?: EMMAUserAppInfo) =>
  Boolean(user && app && isAdminUser(user, app) && app.aclGeneral === 'power_admin');

export const isAdmin = (app: EMMAUserAppInfo) => Boolean(app && app.roleId === EMMA_ROLES.ADMIN);

export const isPowerAdmin = (app: EMMAUserAppInfo) =>
  Boolean(app && isAdmin(app) && app.aclGeneral === 'power_admin');
