import { AfterViewInit, Component, Inject, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EmbedService } from '@emma-services/embed.service';
import { UserService } from '@emma-services/user.service';
import { apiFeedback } from '@platform/helpers/api-feedback.helper';
import { getApiBaseUrl } from '@platform/helpers/base-url.helper';
import { showApiFeedbackMessage } from '@platform/i18n/api-errors.i18n';
import { API_URL } from '@platform/providers/url.provider';
import { AuthenticationService } from '@platform/services/authentication.service';
import { getCurrentLocale } from '@platform/services/locale.service';
import { ToastNotificationsService } from '@platform/services/toast-notifications.service';
import { FEDERATION_TYPE, ROUTE, getAccountDomainsFederationForEmail } from 'emma-common-ts/emma';
import { switchMap } from 'rxjs/operators';
import { AUTH_TYPE } from '../auth-wrapper.component';
import { ERROR_CODES } from 'emma-common-ts';

@Component({
  selector: 'emma-login-page',
  templateUrl: './login.component.html',
})
export class LoginPageComponent implements AfterViewInit {
  loading = new Set<string>();
  type = AUTH_TYPE.LOGIN;

  @Input() returnUrl = '';

  @Input() email = '';
  @Input() remember = true;
  password = '';
  // Social google
  googleButtonLocale = '';
  googleCallbackUrl = '';
  // Microsoft federation
  microsoftFederationKey: string | null = null;
  isMicrosoftFederationLogin = false;
  isUserDeleted = false;
  apiBaseUrl = '';
  ROUTE = ROUTE;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly authService: AuthenticationService,
    private readonly userService: UserService,
    private readonly embedService: EmbedService,
    public notifications: ToastNotificationsService,
    @Inject(API_URL) apiUrl: string
  ) {
    this.apiBaseUrl = getApiBaseUrl(apiUrl);
    this.googleCallbackUrl = this.apiBaseUrl.endsWith('/')
      ? `${this.apiBaseUrl}oauth2/google/callback`
      : `${this.apiBaseUrl}/oauth2/google/callback`;

    this.googleButtonLocale = getCurrentLocale();
  }

  ngAfterViewInit(): void {
    // Algunos gestores de contraseñas cargan el formulario antes de que se cargue la página
    this.onEmailChange();
    this.embedService.checkEmbedMode(this.activatedRoute.snapshot);
    this.returnUrl = this.activatedRoute.snapshot.queryParams['returnUrl'] || '/';
    const errorCode = Number(this.activatedRoute.snapshot.queryParams['errorCode']);
    if (errorCode && !isNaN(errorCode)) {
      showApiFeedbackMessage(this.notifications, errorCode);
    }
  }

  login(): void {
    this.loading.add('login');
    this.authService
      .login(this.email, this.password)
      .pipe(
        switchMap((res) => {
          if (res?.access_token) {
            this.userService.updateUser(res);
          }
          const user = this.userService.getCurrentUser().getValue();
          return this.userService.verify(user);
        }),
        apiFeedback('login', this.notifications, this.loading)
      )
      .subscribe({
        next: () => {
          this.notifications.clear();
          this.router.navigateByUrl(this.returnUrl || '/').catch(console.error);
        },
        error: (response) => {
          if (response?.error?.code === ERROR_CODES.USER_IS_DELETED) {
            this.isUserDeleted = true;
          }
        },
      });
  }

  isValid(): boolean {
    return Boolean(this.email && this.password);
  }

  onSubmit(ev: Event): void {
    ev.preventDefault();
    if (this.isValid()) {
      this.login();
    }
  }

  onEmailChange(): void {
    this.isMicrosoftFederationLogin = false;
    this.microsoftFederationKey = null;
    if (this.email) {
      const accountDomainsFederation = getAccountDomainsFederationForEmail(this.email);
      if (accountDomainsFederation && accountDomainsFederation.type === FEDERATION_TYPE.MICROSOFT) {
        this.isMicrosoftFederationLogin = true;
        this.microsoftFederationKey = accountDomainsFederation.accountKey;
      }
    }
  }

  onMicrosoftLogin() {
    if (this.isMicrosoftFederationLogin && this.microsoftFederationKey) {
      const microsoftCallback = this.apiBaseUrl.endsWith('/')
        ? `${this.apiBaseUrl}oauth2/microsoft/${this.microsoftFederationKey}/authorization`
        : `${this.apiBaseUrl}/oauth2/microsoft/${this.microsoftFederationKey}/authorization`;

      window.open(microsoftCallback, '_self');
    }
  }
}
