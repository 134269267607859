import { formatNumber } from '@emma-helpers/format.helper';
import { SelectOption } from '@platform/types';
import dayjs from 'dayjs';
import { DATE_FORMATS, safeDiv } from 'emma-common-ts';
import {
  AccountPlan,
  AccountPlanConfigurationBase,
  AccountPlanConfigurationV1,
  AccountPlanConfigurationV2,
  PLAN_CONTACT_TYPE,
  PLAN_SUPPORT_PRODUCT,
  PLAN_TYPE,
  PLAN_VERSION,
  Plan,
  PlanConfigurationBase,
  PlanConfigurationV1,
  PlanConfigurationV2,
  PlanPriceRange,
  PriceValue,
} from 'emma-common-ts/emma';
import { find } from 'lodash';

export const CONTACT_MAIL = {
  HIGH_VOLUMEN: $localize`Contacto plan de alto volumen`,
  PLAN_MASTER: $localize`Contacto desde plan MASTER`,
  PLAN_ENTERPRISE: $localize`Contacto desde plan EMPRESA`,
  DOWNGRADE_PLAN: $localize`¡Tu nuevo Plan de EMMA! | Solicitud de reducción de plan`,
  PUBLIC_CONFIGURATOR: $localize`Contacto desde el configurador público`,
  DECISION_PROBLEMS: $localize`Contacto desde problemas para decidirse`,
  CANCEL_PLAN: $localize`¡Esperamos que vuelvas pronto! | Solicitud de cancelación de la cuenta`,
};

export const getContractedPlan = (plans: Plan[], accountPlan: AccountPlan): Plan | undefined =>
  find(plans, { id: accountPlan.planId });

export const getDefaultAccountPlanConfigV1 = (): AccountPlanConfigurationV1 => {
  return {
    totalPrice: 0,
    adquisitionAdquired: false,
    retentionAdquired: false,
    adquisitionAdquiredTotal: 0,
    retentionAdquiredTotal: 0,
    automationAdquired: false,
    apiAdquired: false,
    annualPaymentActive: false,
    skudoAdquired: false,
  };
};

export const getDefaultPlanConfigV1 = (): PlanConfigurationV1 => {
  return {
    maxRetention: 0,
    maxAdquisition: 0,
    adquisitionStep: 0,
    retententionStep: 0,
    adquisitionRanges: [],
    retentionRanges: [],
    automationPercent: 0,
    apiPercent: 0,
    discountAnnualPaymentPercent: 0,
    discountPremiumPercent: 0,
    defaultCostAdquisition: 0,
    defaultCostRetention: 0,
    extraCostAdquisition: 0,
    extraCostRetention: 0,
  };
};

const stepInRange = (value: number, priceRange: PlanPriceRange): boolean =>
  value >= priceRange.start && value <= priceRange.end;

export const calculateAdquisitionRetentionPrice = (
  total: number,
  rangePrices: Array<PlanPriceRange>,
  defaultPrice: number
): number => {
  const foundRange = rangePrices.find((rangePrice) => stepInRange(total, rangePrice));
  const price = foundRange ? foundRange.price : defaultPrice;
  return price * total;
};

export const getDefaultCostAdq = (
  accountPlanConfig?: AccountPlanConfigurationV1,
  planConfig?: PlanConfigurationV1
): number => {
  if (accountPlanConfig && accountPlanConfig.customCostAdquisition) {
    return accountPlanConfig.customCostAdquisition;
  }
  return (planConfig && planConfig.defaultCostAdquisition) || 0;
};

export const getDefaultCostRet = (
  accountPlanConfig?: AccountPlanConfigurationV1,
  planConfig?: PlanConfigurationV1
): number => {
  if (accountPlanConfig && accountPlanConfig.customtCostRetention) {
    return accountPlanConfig.customtCostRetention;
  }
  return (planConfig && planConfig.defaultCostRetention) || 0;
};

export const getPriceRangesAdq = (
  accountPlanConfig?: AccountPlanConfigurationV1,
  planConfig?: PlanConfigurationV1
): Array<PlanPriceRange> => {
  if (accountPlanConfig && accountPlanConfig.customAdquisitionRanges) {
    return accountPlanConfig.customAdquisitionRanges;
  }
  return (planConfig && planConfig.adquisitionRanges) || [];
};

export const getPriceRangesRet = (
  accountPlanConfig?: AccountPlanConfigurationV1,
  planConfig?: PlanConfigurationV1
): Array<PlanPriceRange> => {
  if (accountPlanConfig && accountPlanConfig.customRetentionRanges) {
    return accountPlanConfig.customRetentionRanges;
  }
  return (planConfig && planConfig.retentionRanges) || [];
};

export const calculateAnnualPrice = (
  value: number,
  accountPlanConfig?: AccountPlanConfigurationV1,
  planConfig?: PlanConfigurationV1
): number => {
  if (accountPlanConfig && planConfig) {
    const discountToApply = 1 - safeDiv(planConfig.discountAnnualPaymentPercent, 100);
    const discount = discountToApply < 1 && discountToApply > 0 ? discountToApply : 1;
    return value * 12 * discount;
  }
  return 0;
};

export const getExtraCostAdq = (
  accountPlanConfig?: AccountPlanConfigurationBase,
  planConfig?: PlanConfigurationBase
): number => {
  if (accountPlanConfig && accountPlanConfig.customExtraCostAdquisition) {
    return accountPlanConfig.customExtraCostAdquisition;
  }
  return (planConfig && planConfig.extraCostAdquisition) || 0;
};

export const getExtraCostRet = (
  accountPlanConfig?: AccountPlanConfigurationBase,
  planConfig?: PlanConfigurationBase
): number => {
  if (accountPlanConfig && accountPlanConfig.customExtraCostRetention) {
    return accountPlanConfig.customExtraCostRetention;
  }
  return (planConfig && planConfig.extraCostRetention) || 0;
};

/** PLANES V2 */
export const getDefaultPlanConfigV2 = (): PlanConfigurationV2 => {
  return {
    maxAdquisition: 0,
    maxRetention: 1000000,
    retentionRanges: [
      {
        value: 25000,
        price: 200,
      },
      {
        value: 50000,
        price: 400,
      },
      {
        value: 75000,
        price: 600,
      },
      {
        value: 100000,
        price: 800,
      },
    ],
    adquisitionRanges: [],
    apiPercent: 0.2,
    supportPercent: 0,
    accountManagerPercent: 0.2,
    customerSuccessPercent: 0.3,
    extraCostAdquisition: 0,
    extraCostRetention: 0,
    version: PLAN_VERSION.V2,
  };
};

export const getDefaultAccountPlanConfigV2 = (): AccountPlanConfigurationV2 => {
  return {
    totalPrice: 0,
    discount: 0,
    adquisitionAdquiredTotal: 0,
    retentionAdquiredTotal: 25000,
    annualPaymentActive: false,
    version: PLAN_VERSION.V2,
    permissions: {
      adquisitionActive: true,
      retentionActive: true,
      apiActive: false,
      supportActive: true,
      accountManagerActive: false,
      customerSuccessActive: false,
      audiencesActive: false,
      selfAttributingActive: false,
      retargetingActive: false,
      nativeAdsActive: false,
      levelRolesActive: false,
      usersManagementActive: false,
      importSftpActive: false,
      skudoActive: false,
    },
  };
};

export const getSupportProductByPermissions = (
  accountPlanConfig: AccountPlanConfigurationV2
): PLAN_SUPPORT_PRODUCT => {
  if (accountPlanConfig.permissions.accountManagerActive) {
    return PLAN_SUPPORT_PRODUCT.ACCOUNT_MANAGER;
  } else if (accountPlanConfig.permissions.customerSuccessActive) {
    return PLAN_SUPPORT_PRODUCT.CUSTOMER_SUCCESS;
  }
  return PLAN_SUPPORT_PRODUCT.BASIC;
};

export const calculateMausPrice = (selectedMaus: number, priceRanges: Array<PriceValue>): number => {
  return priceRanges.find((vm) => vm.value === selectedMaus)?.price || 0;
};

export const calculateSupportTotalPrice = (
  basePrice: number,
  accountConfiguration: AccountPlanConfigurationV2,
  configuration: PlanConfigurationV2
): number => {
  if (accountConfiguration.permissions.customerSuccessActive) {
    return basePrice * configuration.customerSuccessPercent;
  } else if (accountConfiguration.permissions.accountManagerActive) {
    return basePrice * configuration.accountManagerPercent;
  }
  return 0;
};

export const calculateApiTotalPrice = (
  basePrice: number,
  accountConfiguration: AccountPlanConfigurationV2,
  planConfiguration: PlanConfigurationV2
): number => {
  return accountConfiguration.permissions.apiActive ? basePrice * planConfiguration.apiPercent : 0;
};

export const addNotSelectablePermissions = (
  planType: PLAN_TYPE,
  configuration: AccountPlanConfigurationV2
): void => {
  if (planType === PLAN_TYPE.GROWTH) {
    configuration.permissions = {
      ...configuration.permissions,
      audiencesActive: false,
      selfAttributingActive: false,
      retargetingActive: false,
      nativeAdsActive: false,
      levelRolesActive: false,
      importSftpActive: false,
    };
  } else {
    configuration.permissions = {
      ...configuration.permissions,
      audiencesActive: true,
      selfAttributingActive: true,
      retargetingActive: true,
      nativeAdsActive: true,
      levelRolesActive: false,
      importSftpActive: false,
    };
  }
};

export const calculateNextInvoicingDate = (accountPlan: AccountPlan): string => {
  const currentDate = dayjs();
  return dayjs(accountPlan.createdAt).year(currentDate.year()).add(1, 'month').format(DATE_FORMATS.API);
};

export const getSectionFromContactTypeV2 = (contactType: PLAN_CONTACT_TYPE): string => {
  if (PLAN_CONTACT_TYPE.DOWNGRADE === contactType) {
    return CONTACT_MAIL.DOWNGRADE_PLAN;
  } else if (PLAN_CONTACT_TYPE.CANCEL_PLAN === contactType) {
    return CONTACT_MAIL.CANCEL_PLAN;
  }
  return CONTACT_MAIL.PLAN_ENTERPRISE;
};

export const getAllVolumenMaus = (): SelectOption<number>[] => {
  const prefixVolumeMaus = $localize`Hasta`;
  const fixedVols = [
    { label: `${prefixVolumeMaus} ${formatNumber(25000)}`, value: 25000 },
    { label: `${prefixVolumeMaus} ${formatNumber(50000)}`, value: 50000 },
    { label: `${prefixVolumeMaus} ${formatNumber(75000)}`, value: 75000 },
  ];
  const autogenerateVols = [];
  for (let i = 2; i <= 20; i++) {
    const value = i * 50000;
    autogenerateVols.push({ label: `${prefixVolumeMaus} ${formatNumber(value)}`, value });
  }
  return [...fixedVols, ...autogenerateVols];
};
