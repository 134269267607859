import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { User } from 'emma-common-ts/emma';
import { catchError, map, Observable, of } from 'rxjs';
import { ROUTE } from 'emma-common-ts/skudo';
import { UserService } from '@emma-services/user.service';
import { AppsService } from '@emma-services/apps.service';

const selectCurrentApp = (
  appsService: AppsService,
  user: Partial<User>,
  accountApiKey: string,
  emmaApiKey?: string
): void => {
  if (user.apps && accountApiKey) {
    let firstApp: number | undefined = undefined;
    for (const app of user.apps) {
      const appId = app.appConfig.appId;
      if (app.accountApiKey === accountApiKey) {
        firstApp ??= appId;
        if (!emmaApiKey || app.appConfig.apiKey === emmaApiKey) {
          appsService.selectCurrentApp(appId, { canReload: false });
          return;
        }
      }
    }
    // la primera válida de la cuenta seleccionada
    if (firstApp) {
      appsService.selectCurrentApp(firstApp, { canReload: false });
    }
  }
};

export const autoLoginGuardFn = (
  _route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean> | boolean => {
  const router = inject(Router);
  const userService = inject(UserService);
  const appsService = inject(AppsService);

  const queryParams = new URLSearchParams(state.url.split('?')[1]);
  const emmaToken = queryParams.get('token') ?? undefined;
  const emmaAccountKey = queryParams.get('account') ?? undefined;
  const emmaApiKey = queryParams.get('app') ?? undefined;

  if (emmaToken && emmaAccountKey) {
    const user: Pick<User, 'access_token'> = { access_token: emmaToken };

    return userService.fetchUser(user).pipe(
      map((userData) => {
        if (userData) {
          selectCurrentApp(appsService, userData, emmaAccountKey, emmaApiKey);
          router.navigateByUrl(ROUTE.ROOT);
          return false;
        }
        return true;
      }),
      catchError(() => {
        return of(true);
      })
    );
  }
  return of(true);
};
