import { Inject, Injectable } from '@angular/core';
import { AuthHeaders } from '@platform/helpers/auth-headers.class';
import { ApiService } from '@platform/services/api.service';
import { ApiChangeResponse, ApiResponse, STATUS } from 'emma-common-ts';
import {
  AccountVerifyEmailResponse,
  DataSegmentAttributes,
  DataSegmentPreview,
  EMMAUserScopeResponse,
  EMMA_API_VERSION,
  SignUpData,
  User,
  UserProfile,
} from 'emma-common-ts/emma';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { LOCAL_STORAGE_KEY, LocalStorageService } from './local-storage.service';

export const getDefaultUser = (): User => ({
  username: '',
  access_token: '',
  refresh_token: '',
  apps: [],
  passwordRenewal: false,
});

@Injectable({ providedIn: 'root' })
export class UserService {
  $user: BehaviorSubject<User | undefined>;
  constructor(
    private apiService: ApiService,
    private localStorageService: LocalStorageService,
    @Inject(AuthHeaders) private authHeaders: AuthHeaders
  ) {
    this.$user = new BehaviorSubject(this.localStorageService.get(LOCAL_STORAGE_KEY.CURRENT_USER));
  }

  private setCurrentUser(user: User): BehaviorSubject<User | undefined> {
    this.$user.next(user);
    return this.$user;
  }

  getCurrentUser(): BehaviorSubject<User | undefined> {
    return this.$user;
  }

  verify(user: Pick<User, 'access_token'> | null | undefined): Observable<EMMAUserScopeResponse> {
    if (!user) {
      return throwError(() => 'No user found');
    }
    const headers = this.authHeaders
      .get()
      .set('X-Api-Version', EMMA_API_VERSION.V20)
      .set('Authorization', `Bearer ${user.access_token}`);

    return this.apiService.get<EMMAUserScopeResponse>(`/oauth2/scope`, {}, { headers });
  }

  fetchUser(
    user: Pick<User, 'access_token'> | undefined = this.getCurrentUser().value
  ): Observable<Partial<User>> {
    return this.verify(user).pipe(
      map((userScope) => {
        const userLogin = {
          ...userScope.user,
          access_token: user?.access_token,
          apps: Object.values(userScope.scope.apps),
        };
        this.updateUser(userLogin);
        return userLogin;
      })
    );
  }

  saveUser(user: User): BehaviorSubject<User | undefined> {
    const updated = { ...getDefaultUser(), ...user };
    this.localStorageService.set(LOCAL_STORAGE_KEY.CURRENT_USER, updated);
    return this.setCurrentUser(updated);
  }

  updateUser(user: Partial<User>): BehaviorSubject<User | undefined> {
    const updated = { ...getDefaultUser(), ...this.$user.value, ...user };
    return this.saveUser(updated);
  }

  updateUserProfile(userProfile: UserProfile): Observable<ApiChangeResponse> {
    return this.apiService.put('/account/user', userProfile).pipe(
      map((res) => {
        if (res && res.status === STATUS.SUCCESS) {
          this.updateUser({
            ...userProfile,
            user: userProfile.email,
            username: userProfile.email,
            passwordRenewal: userProfile.passwordRenewal,
          });
        }
        return res;
      })
    );
  }

  removeUser(): BehaviorSubject<User | undefined> {
    this.$user.next(undefined);
    this.localStorageService.remove(LOCAL_STORAGE_KEY.CURRENT_USER);
    return this.$user;
  }

  /**
   * Registra un nuevo usuario en la API
   */
  signUp(data: SignUpData): Observable<ApiChangeResponse> {
    return this.apiService.post('/account', data);
  }

  forgotPassword(email: string): Observable<ApiChangeResponse> {
    return this.apiService.post('/user/forgotPassword', { email });
  }

  changePassword(password: string, oldPassword: string): Observable<ApiChangeResponse> {
    return this.apiService.put('/user/password', { password, oldPassword });
  }

  recoverPassword(password: string, email: string, verifyToken: string): Observable<ApiChangeResponse> {
    return this.apiService.put('/user/recoverPassword', {
      password,
      email,
      verifyToken,
    });
  }

  validateDataSegmentCsv(contentCsv: DataSegmentAttributes): Observable<ApiResponse<DataSegmentPreview>> {
    return this.apiService.post<ApiResponse<DataSegmentPreview>>('/user/uploadAttributesPreview', contentCsv);
  }

  uploadDataSegmentCsv(contentCsv: DataSegmentAttributes): Observable<ApiChangeResponse> {
    return this.apiService.post('/user/uploadAttributes', contentCsv);
  }

  verifyIfUserEmailExists(email: string): Observable<ApiResponse<AccountVerifyEmailResponse>> {
    return this.apiService.post<ApiResponse<AccountVerifyEmailResponse>>('/account/verify-email', { email });
  }
}
