import { CommonModule, Location } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AlertComponent } from '@emma-components/alert.component';
import { EMMANotificationModule } from '@emma-components/emma-notification.module';
import { EMMAUIModule } from '@emma-components/emmaui.module';
import { EMMAFormModule } from '@emma-components/forms/emma-form.module';
import { EMMADirectivesModule } from '@emma-directives/emma-directives.module';
import { NoUserGuard } from '@emma-guards/no-user.guard';
import { EMMAScriptInjectorsModule } from '@emma/_script-injectors/script-injectors.module';

import { AuthWrapperComponent } from './auth-wrapper.component';
import { TokenLoginPageComponent } from './auto-login/auto-login.component';
import { ForgotPasswordPageComponent } from './forgot-password/forgot-password.component';
import { LoginPageComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { RegisterPageComponent } from './register/register.component';

import type { RouterRoutes } from '@platform/types/router-route.model';
import { autoLoginGuardFn } from '@emma-guards/auto-login.guard';
const routes: RouterRoutes = [
  { path: 'login', canActivate: [autoLoginGuardFn], component: LoginPageComponent },
  { path: 'logout', component: LogoutComponent },
  {
    path: 'register',
    canActivate: [NoUserGuard],
    component: RegisterPageComponent,
  },
  {
    path: 'forgot-password',
    canActivate: [NoUserGuard],
    component: ForgotPasswordPageComponent,
  },
  {
    path: 'auto-login',
    canActivate: [NoUserGuard],
    component: TokenLoginPageComponent,
  },
];
@NgModule({
  declarations: [
    AlertComponent,
    AuthWrapperComponent,
    ForgotPasswordPageComponent,
    ForgotPasswordPageComponent,
    LoginPageComponent,
    LogoutComponent,
    RegisterPageComponent,
    TokenLoginPageComponent,
  ],
  imports: [
    CommonModule,
    EMMADirectivesModule,
    EMMAFormModule,
    EMMANotificationModule,
    EMMAUIModule,
    FormsModule,
    HttpClientModule,
    EMMAScriptInjectorsModule,
    RouterModule.forChild(routes),
  ],
  providers: [Location, RouterModule],
})
export class AuthModule {}
