import {
  ApiRelativeData,
  ApiRelativeTimesData,
  ApiSerialData,
  DateRange,
  DATE_FORMATS,
  INTERNAL_PROP_PREFIX,
  TIME_GROUP,
} from 'emma-common-ts';
import {
  ActivityData,
  ActivityRelative,
  CustomDashboardsFilterConfig,
  EMMA_EVENTS,
  EMMA_KPIS,
  FunnelData,
  LinesChartInput,
  LinesChartParams,
  OS,
  OS_LABEL,
  SerialData,
} from 'emma-common-ts/emma';
import { get, sortBy } from 'lodash';
import moment from 'moment';

export const osLabels: CustomDashboardsFilterConfig[] = [
  { title: OS_LABEL.IOS, id: OS.IOS },
  { title: OS_LABEL.ANDROID, id: OS.ANDROID },
  { title: OS_LABEL.OTHER, id: OS.OTHER },
  { title: OS_LABEL.WINDOWS_PHONE, id: OS.WINDOWS_PHONE },
  { title: OS_LABEL.WEB, id: OS.WEB },
  { title: OS_LABEL.IOS_WEB, id: OS.IOS_WEB },
  { title: OS_LABEL.ANDROID_WEB, id: OS.ANDROID_WEB },
  { title: OS_LABEL.WINDOWS_PHONE_WEB, id: OS.WINDOWS_PHONE_WEB },
];

export const groupByKeys = {
  basic_source: 'source_name',
  campaign: 'campaign_name',
  provider: 'provider_name',
  source: 'source_name',
};

interface Options {
  keepOrder: boolean;
  isPercentage: boolean;
  calculatePercentage: boolean;
}

export const generateDateLabels = (dateRange: DateRange): string[] => {
  const start = moment(dateRange.startDay, DATE_FORMATS.API);
  const end = moment(dateRange.endDay, DATE_FORMATS.API);
  if (dateRange.timeGroup === TIME_GROUP.WEEK) {
    start.startOf('isoWeek');
    end.startOf('isoWeek');
  } else if (dateRange.timeGroup !== TIME_GROUP.DAY) {
    start.startOf(dateRange.timeGroup);
    end.startOf(dateRange.timeGroup);
  }
  const labels: string[] = [];
  let next = start.clone();
  while (next.isSameOrBefore(end)) {
    labels.push(
      next.format(dateRange.timeGroup === TIME_GROUP.MONTH ? DATE_FORMATS.MONTH_YEAR_SHORT : DATE_FORMATS.API)
    );
    next = next.add(1, dateRange.timeGroup);
  }
  return labels;
};

export const parseActivityDataByDate = (
  dataArray: ActivityData[],
  labelKey?: string,
  eventKeys?: Array<Record<number, string>>
): ApiSerialData[] => {
  const resultArray = dataArray.map((d) => {
    const report = d.report[0];
    const keys = Object.keys(report);
    const result: ApiSerialData = {};
    if (!d.date) {
      console.warn('Attempt to parse Activity data without date');
      return result;
    }
    keys
      .filter((k) => !k.startsWith(INTERNAL_PROP_PREFIX) && k !== 'events')
      .forEach((k) => {
        const r: number | string = report[k];
        return (result[k] = r);
      });
    if (labelKey) {
      const labelData = d[labelKey];
      if (!Array.isArray(labelData)) {
        result[labelKey] = labelData;
      }
    }
    if (eventKeys && report.events) {
      let reactivations: number | undefined;
      const availableEvents: string[] = [EMMA_EVENTS.REINSTALL, EMMA_EVENTS.REOPEN];
      report.events.forEach((ev) => {
        const eventKey = eventKeys.find((ek) => Boolean(ek[ev.id]));
        if (eventKey) {
          const label = eventKey[ev.id];
          if (label) {
            if (ev.value === null) {
              result[label] = '-';
            } else {
              result[label] = ev.value;
              if (availableEvents.includes(label)) {
                reactivations = (reactivations || 0) + ev.value || 0;
              }
            }
          }
        }
      });
      if (reactivations !== undefined) {
        result[EMMA_KPIS.REACTIVATIONS] = reactivations;
      }
    }
    return result;
  });
  return resultArray;
};

export const parseActivityRelativeData = (
  data: Partial<ActivityRelative>,
  labelKey: string,
  eventKeys?: Array<Record<number, string>>
): ApiRelativeData => {
  if (eventKeys && data.events) {
    data.events.forEach((ev) => {
      const eventKey = eventKeys.find((ek) => Boolean(ek[ev.id]));
      if (eventKey) {
        const label = eventKey[ev.id];
        if (label) {
          data[label] = { total: ev.total, variation: ev.variation };
        }
      }
    });
  }
  return data[labelKey];
};

export const parseRangeData = <T extends string>(
  dataArray: Array<ApiSerialData<T>> | null,
  dataKey: string,
  labelKey: string,
  stackKey?: string | null,
  filter?: (data: ApiSerialData<T>) => boolean,
  options: Partial<Options> = {}
): SerialData => {
  const values: Record<string, number> = {};
  const labels = new Set<string>();
  const stackLabels = new Set<string>();
  const stackedValues: Record<string, number[]> = {};
  /*
    labels => x labels
    stackLabels => y labels

    stackedValues => { [y labels]: [ ... x values ] }
  */
  if (dataArray) {
    let _filteredData = filter ? dataArray.filter(filter) : dataArray;
    if (!options.keepOrder) {
      _filteredData = sortBy(filter ? dataArray.filter(filter) : dataArray, labelKey);
    }
    // Get the keys
    for (const data of _filteredData) {
      let label = `${get(data, labelKey)}`;
      let stackLabel = stackKey ? `${get(data, stackKey)}` : undefined;
      // Translate OS number to text
      if (labelKey === 'os' && osLabels[Number(get(data, labelKey))]) {
        label = osLabels[Number(get(data, labelKey))].title;
      }
      if (stackKey === 'os' && osLabels[Number(get(data, stackKey))]) {
        stackLabel = osLabels[Number(get(data, stackKey))].title;
      }
      labels.add(label);
      if (stackLabel) {
        stackLabels.add(stackLabel);
      }
    }
    // To search with indexOf
    const labelsArray = Array.from(labels);
    let total = 0;
    if (options.calculatePercentage) {
      total = _filteredData.reduce((acc, d) => acc + Number(get(d, dataKey) || 0), 0);
    }
    // Set the values
    for (const data of _filteredData) {
      let label = `${get(data, labelKey)}`;
      let stackLabel = stackKey ? `${get(data, stackKey)}` : undefined;
      // Translate OS number to text
      if (labelKey === 'os' && osLabels[Number(label)]) {
        label = osLabels[Number(label)].title;
      }
      if (stackKey === 'os' && osLabels[Number(stackLabel)]) {
        stackLabel = osLabels[Number(stackLabel)].title;
      }
      let value = get(data, dataKey) as number;
      if (options.calculatePercentage) {
        value = (value / total) * 100;
      } else if (options.isPercentage) {
        value = value * 100;
      }
      values[label] = (values[label] || 0) + value;
      if (stackLabel) {
        // Fill stack, x labels size, with zeros
        if (!stackedValues[stackLabel]) {
          stackedValues[stackLabel] = Array<number>(labels.size).fill(0);
        }
        // Sum this found value
        const idx = labelsArray.indexOf(label);
        stackedValues[stackLabel][idx] = stackedValues[stackLabel][idx] + value;
      }
    }
  }
  return {
    labels: Array.from(labels),
    values,
    loading: !dataArray,
    stackedValues: stackKey ? stackedValues : undefined,
    stackLabels: Array.from(stackLabels),
  };
};

export const parseRelativeData = (data: ApiRelativeData | null) => {
  return data ? data : { total: 0, variation: 0, loading: true };
};

export const parseStackLinesChart = ({
  labels,
  stackedValues,
  stackLabels,
  loading,
}: {
  labels: string[];
  stackedValues?: Record<string, number[]>;
  stackLabels?: string[];
  loading?: boolean;
}): LinesChartInput[] => {
  const chartData: LinesChartInput[] = [];

  if (!stackLabels || !stackedValues || !stackLabels.length) {
    return [];
  }

  let chartIndex = 0;
  for (const dataKey of stackLabels) {
    const v = stackedValues[dataKey];

    const totalValue = v.reduce((acc, value) => {
      return acc + value;
    }, 0);

    // Si la key no tiene ningún valor numérico, no creamos una linea a cero
    if (!totalValue) {
      continue;
    }

    const valueData: Record<string, number> = {};

    for (const label of labels) {
      const labelIndex = labels.indexOf(label);
      valueData[label] = v[labelIndex];
    }

    chartData.push({
      labels,
      loading,
      graph: {
        index: chartIndex,
        label: dataKey,
        title: dataKey,
        values: valueData,
      },
    });
    chartIndex++;
  }

  return chartData;
};

export const parseLinesChart = (
  { labels, values, loading }: { labels: string[]; values: { [k: string]: number }; loading?: boolean },
  params: LinesChartParams
): LinesChartInput => ({
  labels,
  loading,
  graph: {
    ...params,
    values: { ...values },
  },
});

export const parseHourlyData = (dataArray: ApiSerialData[] | null = []) => {
  const values: Record<string, number> = {};
  const labels: string[] = [];
  const hours: string[] = [];
  for (let i = 0; i < 24; ++i) {
    labels.push(i > 9 ? `${i}:00` : `0${i}:00`);
    hours.push(`HOUR_${i}`);
  }
  if (dataArray) {
    for (const data of dataArray) {
      hours.forEach((hour, i) => {
        const label = labels[i];
        values[label] = Number(values[label] || 0) + Number(data[hour] || 0);
      });
    }
  }
  return { labels, values, loading: !dataArray };
};

export const parseSessionsTimeData = (labels: string[], data: ApiRelativeTimesData) => {
  const values: Record<string, number> = {};
  data.total.forEach((times: number, i: number) => {
    const label = labels[i];
    values[label] = times;
  });
  return {
    labels,
    values,
    total: data.total[labels.length],
    variation: data.variation[labels.length],
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseEventsData = (dataArray: any[], filter?: (data: any) => boolean) => {
  const labels: string[] = [];
  const values: Record<string, number> = {};
  let total = 0;
  for (const data of dataArray) {
    if (!filter || filter(data)) {
      const date = data.start_date || data.end_date || data.scheduled_date || data.date;
      if (date) {
        const label = moment(date, 'YYYY-MM-DD HH:mm').format(DATE_FORMATS.API);
        labels.push(label);
        values[label] = 1 + (values[label] || 0);
        total += 1;
      }
      if (!date) {
        console.warn('No date in data:', data);
      }
    }
  }
  return {
    labels,
    values,
    total,
  };
};

export const parseFunnelData = (data: FunnelData | null) => {
  return data
    ? { total: data.result, variation: 0, loading: false }
    : { total: 0, variation: 0, loading: true };
};

export const MAX_LABEL_LENGTH = 50;
export const limitLabelSize = (label: string) => {
  if (label.length > MAX_LABEL_LENGTH) {
    return `${String(label).slice(0, MAX_LABEL_LENGTH) + '…'}`;
  }
  return label;
};
